$mainHeaderHeight: 56px;

.praxcss-height100vh {
  height: 100vh;
}

.praxcss-avatarButton {
  border: 0;
  padding: 0;
  border-radius: var(--canvas-border-radius--expanded);
}

.topPage-layout-header {
  height: $mainHeaderHeight;
}

.topPage-layout-body {
  height: calc(100vh - $mainHeaderHeight);
}

.topPage-container {
  height: 100%;
}

.fullHeight-container {
  height: 100%;
}

.stop-events-table {
  display: inline-flex;
}

.white-header {
  color: white;
}

.form-field {
  padding: 10px;
  margin: 10px;
}

.C-TableAdvanced__bodyCell {
  vertical-align: inherit;
  .C-Input--type-label {
    display: none;
  }
}
.form-button {
  margin: 10px;
}

.full-width-modal {
  .C-Modal__container {
    min-width: 97vw;
  }
}

.load-details-container {
  padding-top: 32px;
  padding-right: 32px;
  padding-bottom: 0px;
  padding-left: 32px;
}

.centering-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.C-TableAdvanced__bodyCell {
  vertical-align: inherit;
}

.nova-table {
  max-height: 58vh;
}

.blue-chip {
  background: var(--theme-colors-interactive, #3e72db);
  color: var(--canvas-global-color--white);
}

.header-icon-button {
  color: white;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  padding: 0;
}

.header-icon-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (prefers-color-scheme: light) {
  .table-action-button {
    color: black;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    padding: 0;
  }
  .table-action-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .table-action-button:disabled {
    background-color: rgba(0, 0, 0, 0);
  }
}

@media (prefers-color-scheme: dark) {
  .table-action-button {
    color: white;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    padding: 0;
  }
  .table-action-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .table-action-button:disabled {
    background-color: rgba(0, 0, 0, 0);
  }
}
