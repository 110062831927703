.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--canvas-font-color--secondary);
  font-style: italic;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--canvas-border-color--input);
}

.separator:not(:empty)::before {
  margin-right: 10px;
}

.separator:not(:empty)::after {
  margin-left: 10px;
}
