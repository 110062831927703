.capital-input {
  input {
    text-transform: uppercase;
    &::placeholder {
      text-transform: none;
    }
  }
}

.edi-container {
  height: calc(100vh - 150px);
}

.cursor-pointer {
  cursor: pointer;
}
